import A11yAlert from '../a11y/a11y-alert';
export default class AutoPlay {
	constructor(element, onEnd) {
		if (!element) throw Error('No autoPlay element provided');

		this.element = element;
		this.nextItemTitle = this.element.dataset.nextItemTitle;
		this.oldText = this.element.innerHTML;
		this.focusElement = document.querySelector('[js-element~="playerClose"]');
		this.buttonIcon = this.element.querySelector(
			'[js-element~="buttonIcon"]:nth-child(1)'
		);
		this.time = Number(this.element.dataset.autoplayTime) || 10;
		this.countDownTime = this.time;
		this.interval = 1000; // 1 second
		this.cancelAutoPlayItems = document.querySelectorAll(
			'[js-element~="cancelAutoPlay"]'
		);
		this.label = document.createElement('span');
		this.timer;
		this.pauze = false;
		this.onEnd = onEnd ? onEnd : () => {};
		this.onStart();
	}

	onStart = () => {
		document.addEventListener('visibilitychange', this.controlPause);
		new A11yAlert(
			window.i18n.autoPlayA11y(this.countDownTime, this.nextItemTitle)
		);

		this.cancelAutoPlayItems.forEach((item) => {
			item.addEventListener('click', this.stopAutoPlay);
		});

		document.addEventListener('keydown', this.onKeyDown);

		// init the elements
		this.element.innerText = '';
		this.element.classList.add('btn--progress');
		this.label.innerHTML = `${window.i18n.autoPlay} <span class="button__timer">${this.countDownTime}</span>`;
		this.element.style.setProperty('--_progress-max-time', `${this.time}s`);
		this.element.prepend(this.buttonIcon);
		this.element.appendChild(this.label);

		this.timer = setInterval(this.intervalFunction, this.interval);
	};

	controlPause = () => {
		this.pauze = document.hidden;
	};

	onKeyDown = (e) => {
		if (e.key === 'Escape' || e.key === 'Esc') {
			this.stopAutoPlay();
			if (this.focusElement) this.focusElement.focus();
		}
	};

	stopAutoPlay = () => {
		clearInterval(this.timer);

		// Reset ALL the eventListeners and styles
		this.element.style.minWidth = 'auto';
		this.element.classList.remove('btn--progress', 'is-animating');
		document.removeEventListener('keyDown', this.onKeyDown);
		document.removeEventListener('visibilitychange', this.controlPause);
		this.cancelAutoPlayItems.forEach((item) => {
			item.removeEventListener('click', this.stopAutoPlay);
		});

		// Put the old text back
		this.element.innerHTML = this.oldText;
	};

	intervalDone = () => {
		clearInterval(this.timer);
		document.removeEventListener('keyDown', this.onKeyDown);

		try {
			this.onEnd();
		} catch (error) {
			throw new Error(
				`Error in onEnd callback for AutoPlay: ${error.message}`,
				error
			);
		}
	};

	intervalFunction = () => {
		if (this.countDownTime === this.time) {
			this.element.classList.add('is-animating');
		}

		this.label.innerHTML = `${window.i18n.autoPlay} <span class="button__timer">${this.countDownTime}</span>`;

		if (this.countDownTime === 0) {
			this.intervalDone();
			return;
		}

		if (this.pauze) {
			return;
		}

		this.countDownTime--;
	};
}
