import teaserEdit from '../teaser-edit/teaser-edit';
import A11yAlert from '../../classes/a11y/a11y-alert';
import Modal from '../../classes/modal/modal';
import { isAlreadyModalShowing } from '../../utils/checkQueue/checkQueue';
import swimlane from '../swimlane/swimlane';
import Tabs from '../../classes/tabs/tabs';

let modal,
	stateObj = {
		items: [],
	};

window.App = window.App || {};

function init() {
	modal = null;
	window.dataLayer = window.dataLayer || [];

	document
		.querySelectorAll('[js-module~="myList"]')
		.forEach(function (myListButton) {
			new MyListInstance(myListButton);
		});

	if (
		!isAlreadyModalShowing() &&
		window.location.href.indexOf(window.i18n.modal.addToMyList.urlPath) > -1
	) {
		const html = document
			.getElementById('addToMyListModalTemplate')
			?.content.cloneNode(true);

		if (!html) return;

		modal = new Modal(
			'MyListModal',
			{
				message: html,
			},
			{
				onOpen: function () {
					const myListButton = window.MyListModal?.querySelector(
						'[js-module~="myList"]'
					);
					if (myListButton) {
						new MyListInstance(myListButton);
					}
				},
				onClose: updateUrl,
			}
		);
	}

	// To make the Mijn Lijst buttons resemble the correct state after using the back button
	window.addEventListener(
		'popstate',
		(e) => {
			if (e.state && e.state.items) {
				const ids = e.state.items;

				ids.forEach((id) => {
					toggleButtonState(
						document.querySelector(
							`[js-module~="myList"][data-asset-id="${id}"]`
						),
						true
					);
				});
			}
		},
		{ passive: true }
	);

	myListAndPurchasesTabs();
}

const MyListInstance = function (button) {
	const form = button.form;

	form.addEventListener('submit', (e) => {
		fetchMyList();
		button.blur();
		e.preventDefault();
	});

	async function fetchMyList() {
		button.classList.add('is-processing');

		const response = await fetch(form.action, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'x-csrf-token': window.App?.csrfToken,
			},
		});

		button.classList.remove('is-processing');

		if (!response.ok) {
			showError();
			return;
		}

		onSuccess(button);
	}
};

function onSuccess(button) {
	trackMyListInteraction(button.form);
	// instantly update the state of the My List button
	toggleButtonState(button.dataset.assetId);
	// for screen readers only
	giveSuccessfulA11yFeedback(button.dataset.state);

	if (modal) {
		modal.close();
		modal = null;
	} else {
		button.focus();
	}
}

function toggleButtonState(assetId, silent) {
	let updated = false;

	const allMyListButtonsWithSameAssetId = document.querySelectorAll(
		`[js-module~="myList"][data-asset-id="${assetId}"]`
	);

	allMyListButtonsWithSameAssetId.forEach((button) => {
		const form = button.form;
		const endpoint = form.dataset.endpoint;
		let actionUrl = form.getAttribute('action');
		let state = button.dataset.state;
		let ariaLabel = button.getAttribute('aria-labelledby').split(' ');

		if (state === 'notaddedtomylist') {
			button.dataset.state = 'addedtomylist';
			button.setAttribute(
				'aria-labelledby',
				`${ariaLabel[0]} ${form.id}-removeMessage`
			);
		} else {
			button.dataset.state = 'notaddedtomylist';
			button.setAttribute(
				'aria-labelledby',
				`${ariaLabel[0]} ${form.id}-addMessage`
			);
		}

		if (actionUrl) {
			if (actionUrl.endsWith('/add')) {
				actionUrl = actionUrl.replace(/\/add/, '/remove');
			} else {
				actionUrl = actionUrl.replace(/\/remove/, '/add');
			}

			form.action = actionUrl;
		}
		// update the My List swimlane if you clicked on `add|remove` in the (top) banner
		if (!updated && endpoint && !silent) {
			updateMyListSwimlane(endpoint);
			updated = true;
		}
	});

	updateMyListIds(assetId);
}

async function updateMyListSwimlane(url) {
	const myListRows = document.querySelectorAll('.row--mylist');
	const topbannerRows = document.querySelectorAll('.row--top-banner');

	const response = await fetch(url, {
		method: 'GET',
		headers: {
			'Content-Type': 'text/xml',
			'X-Requested-With': 'XMLHttpRequest',
		},
	});

	if (!response.ok) {
		// if there are 0 items in the swimlane, it returns a 404
		if (response.status === 404) {
			if (myListRows.length) {
				myListRows.forEach((row) => {
					row.remove();
					row = null;
				});
			}
		} else {
			showError();
		}

		return;
	}

	const content = await response.text();

	if (myListRows.length) {
		myListRows.forEach((row) => {
			row.insertAdjacentHTML('afterend', content);
			row.remove();
			row = null;
		});
	} else {
		if (topbannerRows.length) {
			topbannerRows[0].insertAdjacentHTML('afterend', content);
		}
	}

	const updatedMyListSwimlanes = document.querySelectorAll(
		'x-swimlane[swimlane-type="mylist"]'
	);

	// create new Mijn Lijst swimlanes
	swimlane.init(updatedMyListSwimlanes);

	// reattach the eventlisteners for the edit functionality & tracking
	updatedMyListSwimlanes.forEach((myListSwimlane) => {
		teaserEdit.createEditableTeaserGroup(myListSwimlane);
	});
}

function updateMyListIds(id) {
	if (!stateObj.items.includes(id)) {
		stateObj.items.push(id);
	} else {
		stateObj.items.splice(stateObj.items.indexOf(id), 1);
	}
	window.history.replaceState(stateObj, null, window.location);
}

function updateUrl() {
	const url = window.location.href;
	const newUrl = new URL('./', url); // remove `toevoegen-aan-mijn-lijst` from the Url path

	window.history.replaceState(
		{
			activeUrl: newUrl.href.slice(0, -1), // strip the trailing /
		},
		document.title,
		newUrl.href.slice(0, -1) // strip the trailing /
	);
}

function giveSuccessfulA11yFeedback(state) {
	const message =
		state === 'addedtomylist'
			? window.i18n?.addedToMyList
			: window.i18n?.removedFromMyList;
	new A11yAlert(message);
}

function trackMyListInteraction(element) {
	if (!element || !element.dataset.tracking) {
		return;
	}

	const trackingData = JSON.parse(element.dataset.tracking);
	const gtmData = {
		event: trackingData.event,
		asset_id: trackingData.assetId ?? null,
		list: null,
	};

	if (trackingData.videoId) {
		gtmData.video = gtmData.video || {};
		gtmData.video.id = trackingData.videoId;
	}

	if (trackingData.listId) {
		gtmData.list = gtmData.list || {};
		gtmData.list.id =
			trackingData.listId === 'null' ? null : trackingData.listId;
	}

	if (trackingData.abGroup) {
		gtmData.list = gtmData.list || {};
		gtmData.list.ab_group =
			trackingData.abGroup === 'null' ? null : trackingData.abGroup;
	}

	if (trackingData.requestId) {
		gtmData.list = gtmData.list || {};
		gtmData.list.requestId =
			trackingData.requestId === 'null' ? null : trackingData.requestId;
	}

	if (trackingData.provider) {
		gtmData.list = gtmData.list || {};
		gtmData.list.provider =
			trackingData.provider === 'null' ? null : trackingData.provider;
	}

	if (trackingData.routingGroup) {
		gtmData.list = gtmData.list || {};
		gtmData.list.routing_group =
			trackingData.routingGroup === 'null' ? null : trackingData.routingGroup;
	}

	window.dataLayer.push(gtmData);

	if (trackingData.event?.includes('continue')) {
		return;
	}
	// Dynamically update the event
	if (trackingData.event === 'add_to_list') {
		trackingData.event = 'remove_from_list';
	} else {
		trackingData.event = 'add_to_list';
	}
	const newTrackingData = JSON.stringify(trackingData);
	document
		.querySelectorAll(
			`[js-module~="myList"][data-asset-id="${trackingData.assetId}"]`
		)
		.forEach((button) => {
			button.form.dataset.tracking = newTrackingData;
		});
}

function showError() {
	new Modal('warning', {
		message: window.i18n?.alertMyListError,
		alertDialog: true,
	});
}

function myListAndPurchasesTabs() {
	const tabs = document.querySelectorAll(
		'[js-module~="myListAndPurchasesTabs"]'
	);

	tabs.forEach((tab) => {
		new Tabs(tab, null, onTabChangeCallback);
	});
}

function onTabChangeCallback(element) {
	let showActions = true;
	if (element === document.querySelector('[js-element="tab"]:first-of-type')) {
		showActions = false;
	}

	const action = document.querySelector('[js-element~="taxonomyTabsAction"]');
	action && toggleTaxonomyActions(showActions, action);
}

function toggleTaxonomyActions(showActions, action) {
	action.classList.toggle('is-hidden', !!showActions);
}

export default {
	init,
	toggleButtonState,
	trackMyListInteraction,
	instance: MyListInstance,
};
