/*
	WCAG - EAA compliant form validation
	includes form validation for the profile creation form
	- checks if the form is valid
	- checks if the date is in the past
	- checks if the user is old enough to create a profile
	- shows an error summary if the form is invalid
	- submit buttons NEVER get disabled if the form is invalid, the user can still submit the form but the submit will be blocked (preventdefault)

	The datepicker is a regular text input field with a date pattern
	The native datepicker is not accessible or usable enough (for this use case)
*/
let form;
let datePicker;
let formFields;
let submitBtn;
let genderLabels;
let kidsToggle;
let errorsSummary;
let formFieldsMap = new Map();
let datePattern;

function init() {
	form = document.querySelector('[js-module~="profileCreate"]');
	datePattern = /^\d{2}\/\d{2}\/\d{4}$/;

	if (!form) return;

	submitBtn = form.querySelector('button[type=submit]');
	datePicker = form.querySelector('[js-element~="profileBirthDate"]');
	formFields = form.querySelectorAll(
		'input:not([type=hidden]):not([type=radio]), [role="radiogroup"]'
	);
	kidsToggle = form.querySelector('[js-element~="profileKidsToggle"]');
	genderLabels = form.querySelectorAll('[js-element~="profileGenderLabel"]');
	errorsSummary = form.querySelector('[js-element~="formErrorsSummary"]');

	// create a Map of the form fields
	formFields.forEach((field, index) => {
		formFieldsMap.set(index, { id: field.id, isValid: true });
	});

	addEventListeners();
}

function addEventListeners() {
	datePicker.addEventListener('focusout', handleDateFocusOut);

	formFields.forEach((field) => {
		field.addEventListener('focusout', handleInputFocusOut);
		field.addEventListener('input', handleInputChange);
	});

	if (kidsToggle) {
		kidsToggle.addEventListener('change', (e) => {
			toggleGenderLabel(e.currentTarget.checked);
		});
	}

	form.addEventListener('submit', handleSubmit);
}

function handleSubmit(event) {
	// it's NOT valid
	if (!isValidForm()) {
		event.preventDefault();
		showErrorsSummary();
	} else {
		// it's VALID !!!!!!
		// to avoid double clicks
		submitBtn.disabled = true;
		hideErrorsSummary();
	}
}

function handleDateFocusOut(e) {
	const kidsProfileCheckbox = document.querySelector(
		'[js-element~="profileKidsToggle"]'
	);
	if (!isMainProfile() && kidsProfileCheckbox) {
		if (isMinimumAge(e.currentTarget.value)) {
			kidsProfileCheckbox.checked = false;
			toggleGenderLabel(false);
		} else {
			toggleGenderLabel(true);
			kidsProfileCheckbox.checked = true;
		}
	}
}

function handleInputFocusOut() {
	let field = this;
	isValidField(field);
}

function handleInputChange() {
	let field = this;
	// only for radiogroups
	if (field.role !== 'radiogroup') return;

	isValidField(field);
}

// Validation
function isValidForm() {
	let isValidForm = true;

	formFields.forEach((field) => {
		const fieldIsValid = isValidField(field);
		if (!fieldIsValid) {
			isValidForm = false;
		}
	});

	return isValidForm;
}

function isValidField(field) {
	let fieldIsValid = true;
	switch (true) {
		case field.dataset?.type === 'date':
			return validateDatePicker(field);
		case field.role === 'radiogroup':
			if (field.getAttribute('aria-required') === 'true') {
				const checkedRadio = field.querySelector('input:checked');
				if (!checkedRadio) {
					fieldIsValid = false;
				}
			}
			setFieldValidity(field, fieldIsValid);
			return fieldIsValid;
		case field.getAttribute('aria-required') === 'true':
			if (field.getAttribute('minlength')) {
				// if it's less than the minlength
				if (field.value.length < field.getAttribute('minlength')) {
					fieldIsValid = false;
				}
			}
			// if it only contains whitespace characters
			if (/^\s+$/.test(field.value)) {
				fieldIsValid = false;
			}
			setFieldValidity(field, fieldIsValid);
			return fieldIsValid;
		// if they aren't required, they are valid
		default:
			return fieldIsValid;
	}
}

function validateDatePicker(element) {
	isValidDatePattern(element.value);
	const date = convertStringToDate(element.value);
	let isValidAge = false;
	if (isValidDatePattern(element.value)) {
		isValidAge = isMainProfile()
			? isMinimumAge(element.value)
			: dateIsInPast(date);
	}

	setFieldValidity(element, isValidAge);

	return isValidAge;
}

function isMainProfile() {
	return datePicker.dataset.profileType === 'main';
}

function isMinimumAge(dateString) {
	const date = convertStringToDate(dateString);
	const minimumAge = isMainProfile()
		? parseInt(datePicker.dataset.minAge, 10)
		: 13;
	const expectedAge = new Date(date).setFullYear(
		date.getFullYear() + minimumAge
	);

	return dateIsInPast(expectedAge);
}

function dateIsInPast(date) {
	return date <= new Date();
}

function setFieldValidity(field, valid) {
	const parent = field.closest('.form__group');
	if (!parent) return;
	// find the index of the element in the formFieldsMap

	// because Safari doesn't support Iterator methods on Map, we have to convert it to an Array
	const index = Array.from(formFieldsMap).find(
		(entry) => entry[1].id === field.id
	)[0];

	// find the entry that has an id property that matches the field.id

	let element = field;
	if (parent.getAttribute('role') === 'radiogroup') {
		element = parent;
	}

	if (!valid) {
		element.setAttribute('aria-invalid', 'true');
		element.setAttribute('aria-describedby', `${element.id}Error`);
	} else {
		element.removeAttribute('aria-invalid');
		element.removeAttribute('aria-describedby');
	}

	if (formFieldsMap.has(index)) {
		formFieldsMap.delete(index);
		formFieldsMap.set(index, { id: element.id, isValid: valid });
	}

	parent.classList.toggle('is-invalid', !valid);
}

function showErrorsSummary() {
	createErrorsSummary();
	errorsSummary.hidden = false;
	errorsSummary.focus();
}

function createErrorsSummary() {
	const errorsList = errorsSummary.querySelector(
		'[js-element~="formErrorsList"]'
	);
	errorsList.innerHTML = '';

	const listFragment = document.createDocumentFragment();
	const sortedErrors = Array.from(formFieldsMap);
	// sort them ascending (0, 1, 2 etc)
	sortedErrors.sort((a, b) => a[0] - b[0]);
	sortedErrors.forEach((error) => {
		// if it's valid, return
		if (error[1].isValid) return;

		const errorsListItem = document.createElement('li');
		const errorsLink = document.createElement('a');
		errorsLink.href = `#${error[1].id}`;
		errorsLink.textContent = window.i18n.profile.errors[error[1].id];
		errorsListItem.appendChild(errorsLink);
		listFragment.appendChild(errorsListItem);
	});
	errorsList.appendChild(listFragment);
}

function hideErrorsSummary() {
	errorsSummary.hidden = true;
}

function toggleGenderLabel(isKids) {
	if (isKids) {
		genderLabels.forEach((label) => {
			label.textContent =
				window.i18n.profile.label[`${label.getAttribute('for')}Kids`];
		});
	} else {
		genderLabels.forEach((label) => {
			label.textContent = window.i18n.profile.label[label.getAttribute('for')];
		});
	}
}

function isValidDatePattern(date) {
	return datePattern.test(date);
}

// Turn the human readable date into a machine readable date
// e.g. 10/06/1974 -> 1974-06-10
function convertStringToDate(dateString) {
	const date = dateString.split('/');
	return new Date(date.reverse().join('-'));
}

export default {
	init,
};
