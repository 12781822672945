import { debounce } from '../../utils/throttle/debounce';

export const SWIMLANE_POSITION_STORAGE_KEY = 'lfvp_swimlane-pos';

export default class Swimlane {
	constructor(element) {
		this.elements = {
			id: element.dataset.id,
			root: element,
			scroller: element.querySelector('x-swimlane__scroller'),
			items: undefined, // set in start
			numberOfVisibleItems: undefined, // set in start
			visibleItems: [], // set in start
			previous: element.querySelector('[data-direction="previous"]'),
			next: element.querySelector('[data-direction="next"]'),
		};

		this.infinite =
			this.elements.scroller.dataset.infinite === 'false' ? false : true;
		this.swimlaneHasBeenScrolledBefore = false;
		this.size = undefined; // amount of items - set in start
		this.numberOfCompleteGroups = undefined; // number of groups with the full amount of visible items - set in start
		this.totalNumberOfGroups = undefined; // all groups included the remainder - set in start
		this.itemWidth = undefined; // width of 1 item - set in start
		this.shift = undefined; // set in getStartPosition - the amount of % the swimlane scroller needs to scroll
		this.remainder = undefined; // modulo - set in start
		this.compensator = undefined; // set in start
		this.activeGroup = undefined; // set in start
		this.hasPrependedGroup = false;
		this.isLastGroup = false;
		this.isFirstGroup = true;
		this.firstGroupNumber = null;
		this.storeGroupPosition =
			this.elements.scroller.dataset.storeGroupPosition === 'false'
				? false
				: true;

		this.inPreviousDirection = false;
		this.inNextDirection = false;

		// based on the amount of rows, you need to multiply the amount
		// of visibleItems
		// e.g. the genre collections swimlane on search
		this.rows =
			this.elements.root.dataset.rows !== undefined
				? Number(this.elements.root.dataset.rows)
				: 1;

		// all hell breaks loose
		this.start();
	}

	start() {
		// start settings
		// get the custom property from CSS that define how many items are in 1 group
		this.elements.numberOfVisibleItems =
			new Number(
				window
					.getComputedStyle(this.elements.root)
					.getPropertyValue('--_swimlane-visible-items')
			) * this.rows;

		this.elements.root.classList.add('is-activated');

		// count all children of the scroller, not only the items
		this.elements.items = Array.from(
			this.elements.root.querySelectorAll('x-swimlane__scroller > *')
		);

		this.size = this.elements.items.length;
		this.numberOfCompleteGroups = Math.floor(
			this.size / this.elements.numberOfVisibleItems
		);
		this.totalNumberOfGroups = Math.ceil(
			this.size / this.elements.numberOfVisibleItems
		);
		this.firstGroupNumber = 1;

		this.elements.scroller.setAttribute('role', 'group');
		this.elements.scroller.setAttribute(
			'aria-labelledby',
			`swimlane-title-${this.elements.id}`
		);
		// stop everything if there's only 1 group (or less)
		if (this.totalNumberOfGroups <= 1) return;

		this.elements.scroller.setAttribute('aria-roledescription', 'carousel');

		this.itemWidth = 100 / this.elements.numberOfVisibleItems;

		// compensation for the extra dummy item on the prepends & appends
		this.compensator = this.infinite ? this.itemWidth : 0;

		// modulo on total items:
		// e.g. 20 items / 6 visible items = 2
		this.remainder = this.size % this.elements.numberOfVisibleItems;
		this.activeGroup = this.getStartPosition();
		this.shift = this.getShift();
		// end settings

		this.elements.next.hidden = false;

		if (!this.swimlaneHasBeenScrolledBefore) {
			this.listen();
		}

		// go to the stored position or the activeElement, if any
		// this is retrieved in geStartPosition
		if (this.activeGroup !== this.firstGroupNumber) {
			this.goNext(true);
		}

		// We need to synchronize the swimlane buttons on the start. (we retrigger this when we synchronize the swimlane)
		this.buttonSync();

		this.getElementsInVisibleGroup();
		this.setFirstAndLastClassNames();
		this.setTabindex();
	}

	listen() {
		this.elements.scroller.addEventListener('touchstart', this.swipeHandler, {
			passive: true,
		});

		this.elements.next.addEventListener('click', () => this.goNext(false), {
			passive: true,
		});
		this.elements.previous.addEventListener('click', () => this.goPrevious(), {
			passive: true,
		});
	}

	unlisten() {
		this.elements.scroller.removeEventListener(
			'touchstart',
			this.swipeHandler,
			{
				passive: true,
			}
		);
		this.elements.next.removeEventListener('click', () => this.goNext(false), {
			passive: true,
		});
		this.elements.previous.removeEventListener(
			'click',
			() => this.goPrevious(),
			{
				passive: true,
			}
		);
	}

	// do all the things when the css transition ends
	synchronize = () => {
		if (!this.swimlaneHasBeenScrolledBefore)
			this.swimlaneHasBeenScrolledBefore = true;

		if (this.infinite) {
			if (!this.hasPrependedGroup) {
				// prepend the dummy group for the infinite loop
				this.prependCloneOfLastGroup();
				this.shift = this.shift + 100 + this.compensator;
				this.shiftSwimlane(this.shift, true);
			}

			// if we're on the appended group, jump to the first real group
			if (this.activeGroup > this.totalNumberOfGroups) {
				this.shift = 100 + this.compensator;
				this.shiftSwimlane(this.shift, true);
				this.activeGroup = this.firstGroupNumber;
			}

			// if we're on the prepended group, jump to the last real group
			if (this.activeGroup < this.firstGroupNumber) {
				this.shift =
					this.numberOfCompleteGroups * 100 +
					this.compensator +
					this.remainder * this.itemWidth;
				this.shiftSwimlane(this.shift, true);
				this.activeGroup = this.totalNumberOfGroups;
			}
		}

		this.isFirstGroup = this.activeGroup === this.firstGroupNumber;
		this.isLastGroup = this.activeGroup === this.totalNumberOfGroups;

		this.getElementsInVisibleGroup();
		this.setFirstAndLastClassNames();
		this.buttonSync();
		this.setTabindex();

		// set focus on the first or last item of the visible group
		if (this.inPreviousDirection) {
			const lastItem = this.elements.items.find((item) =>
				item.classList.contains('last')
			);
			lastItem?.querySelector('a').focus();
		}
		if (this.inNextDirection) {
			const firstItem = this.elements.items.find((item) =>
				item.classList.contains('first')
			);
			firstItem?.querySelector('a').focus();
		}

		this.elements.scroller.classList.remove('is-animating');
		// store position in sessionStorage
		if (this.storeGroupPosition) this.storePosition(this.activeGroup);
	};

	buttonSync = () => {
		if (!this.infinite) {
			// if we're in the last group (with remainder)
			if (this.isLastGroup) {
				this.elements.next.hidden = true;
				this.elements.previous.hidden = false;
			} else if (this.isFirstGroup) {
				this.elements.previous.hidden = true;
				this.elements.next.hidden = false;
			} else {
				this.elements.previous.hidden = false;
				this.elements.next.hidden = false;
			}
		}

		this.disableButtons(false);
	};

	disableButtons = (buttonsState) => {
		// disable/enable the click on the prev/next buttons while shifting
		this.elements.next.disabled = buttonsState;
		this.elements.previous.disabled = buttonsState;
	};

	goPrevious = () => {
		this.disableButtons(true);

		this.elements.scroller.addEventListener('transitionend', this.synchronize, {
			passive: true,
			once: true,
		});

		// for the swipes/touches/wheel events on non-infinite swimlanes
		// if we're in the first group, do nothing
		if (!this.infinite && this.isFirstGroup) {
			this.disableButtons(false);
			return;
		}

		// if we're in the first group and swimlane has remainder
		if (this.isLastGroup && this.remainder) {
			this.shift = this.shift - this.remainder * this.itemWidth;
		} else {
			this.shift = this.shift - 100;
		}

		this.inPreviousDirection = true;
		this.inNextDirection = false;
		--this.activeGroup;
		this.shiftSwimlane(this.shift);
	};

	goNext = (instant) => {
		this.disableButtons(true);

		if (!this.swimlaneHasBeenScrolledBefore && this.infinite) {
			// append the dummy group for the infinite loop
			this.appendCloneOfFirstGroup();
			this.elements.previous.hidden = false;
		}

		this.elements.scroller.addEventListener('transitionend', this.synchronize, {
			passive: true,
			once: true,
		});

		// for the swipes/touches events on non-infinite swimlanes
		// if we're in the last group, do nothing
		if (!this.infinite && this.isLastGroup) {
			this.disableButtons(false);
			return;
		}

		// INFINITE LOOPING
		if (this.infinite) {
			if (this.swimlaneHasBeenScrolledBefore) {
				if (this.remainder) {
					if (this.activeGroup === this.numberOfCompleteGroups) {
						this.shift = this.shift + this.remainder * this.itemWidth;
					} else {
						this.shift = this.shift + 100;
					}
				} else {
					/* NO REMAINDER */
					this.shift = this.shift + 100;
				}
			}
			/*
			 * The case if !this.swimlaneHasBeenScrolledBefore
			 * is handled in synchronize()
			 */
		} else {
			/* NOT INFINITE */
			if (this.swimlaneHasBeenScrolledBefore) {
				if (this.remainder) {
					if (this.activeGroup === this.numberOfCompleteGroups) {
						this.shift = this.shift + this.remainder * this.itemWidth;
					} else {
						this.shift = this.shift + 100;
					}
				} else {
					/* NO REMAINDER */
					this.shift = this.shift + 100;
				}
			} else {
				// if we skip the animation due instant synchronize will not trigger
				if (instant) this.synchronize();
			}
			/*
			 * The case if !this.swimlaneHasBeenScrolledBefore
			 * is handled in synchronize
			 */
		}

		this.inPreviousDirection = false;
		this.inNextDirection = true;
		if (!instant) ++this.activeGroup;
		this.shiftSwimlane(this.shift, instant);
	};

	shiftSwimlane = (value, skipAnimation) => {
		let animate = true;

		if (skipAnimation) {
			animate = false;
		}

		const scrollport = this.elements.scroller;

		window.requestAnimationFrame(() => {
			if (animate) {
				scrollport.classList.add('is-animating');
			}

			const pos = `${value * -1}%`;
			this.elements.scroller.style.setProperty('--_swimlane-shift', pos);
		});
	};

	prependCloneOfLastGroup = () => {
		const clonedItemsOfLastGroup = [];

		for (let i = 0; i <= this.elements.numberOfVisibleItems; i++) {
			clonedItemsOfLastGroup.push(
				this.elements.items[this.size - 1 - i].cloneNode(true)
			);
		}

		clonedItemsOfLastGroup.forEach((item, index) => {
			item.removeAttribute('style');
			item.classList.add('clone', 'prepends');
			item.setAttribute('aria-hidden', 'true');
			item.querySelectorAll('a').forEach((link) => {
				link.setAttribute('tabindex', '-1');
			});
			item.querySelectorAll('img').forEach((img) => {
				img.removeAttribute('loading');
			});
			this.elements.scroller.prepend(item);
			if (index === this.elements.numberOfVisibleItems - 1) {
				this.prependItem = item;
			}
		});

		this.hasPrependedGroup = true;
	};

	appendCloneOfFirstGroup = () => {
		const clonedItemsOfFirstGroup = [];

		for (let i = 0; i <= this.elements.numberOfVisibleItems; i++) {
			clonedItemsOfFirstGroup.push(this.elements.items[i].cloneNode(true));
		}

		clonedItemsOfFirstGroup.forEach((item, index) => {
			item.removeAttribute('style');
			item.classList.add('clone', 'appends');

			item.setAttribute('aria-hidden', 'true');
			item.querySelectorAll('a').forEach((link) => {
				link.setAttribute('tabindex', '-1');
			});
			// to make it pretty in Safari
			item.querySelectorAll('img').forEach((img) => {
				img.removeAttribute('loading');
			});
			this.elements.scroller.append(item);
			if (index === 0) {
				this.appendItem = item;
			}
		});
	};

	getShift = () => {
		if (this.activeGroup === this.firstGroupNumber) {
			if (this.remainder && this.numberOfCompleteGroups === 1) {
				return this.remainder * this.itemWidth;
			}
			return this.firstGroupNumber * 100;
		}

		if (this.activeGroup === this.totalNumberOfGroups) {
			return (
				(this.numberOfCompleteGroups - 1) * 100 +
				this.remainder * this.itemWidth
			);
		}

		return (this.activeGroup - 1) * 100;
	};

	getStartPosition = () => {
		// is there a group position stored in sessionStorage?
		const storedPositionObject = JSON.parse(
			window.sessionStorage.getItem(SWIMLANE_POSITION_STORAGE_KEY)
		);
		// is there an item that needs to be visible?
		const activeItem = this.elements.scroller.querySelector(
			'[js-element="swimlaneActiveItem"]'
		);

		// for the tvguide swimlane, the "Now live on TV" item should be visible
		if (activeItem) {
			if (storedPositionObject?.id === this.elements.root.dataset.id) {
				sessionStorage.removeItem(SWIMLANE_POSITION_STORAGE_KEY);
			}

			const activeTeaserIndex = this.elements.items.indexOf(activeItem);
			const groupIndex =
				Math.floor(activeTeaserIndex / this.elements.numberOfVisibleItems) + 1;

			return groupIndex;
		}

		// only try to get a stored position if this swimlane has it enabled
		if (storedPositionObject && this.storeGroupPosition) {
			if (storedPositionObject.id === this.elements.root.dataset.id) {
				if (storedPositionObject.groupIndex > this.totalNumberOfGroups) {
					return this.totalNumberOfGroups;
				}
				return storedPositionObject.groupIndex;
			}
		}

		return this.firstGroupNumber;
	};

	storePosition = (index) => {
		if (index <= this.firstGroupNumber) {
			window.sessionStorage.removeItem(SWIMLANE_POSITION_STORAGE_KEY);
		} else if (this.elements.root.dataset.id) {
			window.sessionStorage.setItem(
				SWIMLANE_POSITION_STORAGE_KEY,
				JSON.stringify({
					id: this.elements.root.dataset.id,
					groupIndex: index,
				})
			);
		}
	};

	// We need these classes as hooks for certain other JS modules
	// e.g.: Top 10
	setFirstAndLastClassNames = () => {
		// remove any exisiting first/last classNames
		this.elements.items.forEach((item) => {
			item.classList.remove('first', 'last');
		});

		let index = 0;
		// resetting to zero-based index
		index = this.activeGroup - 1;

		// add 'first' an 'last' to the first item in a visible group
		if (this.activeGroup <= this.numberOfCompleteGroups) {
			this.elements.items[
				index * this.elements.numberOfVisibleItems
			].classList.add('first');
			this.elements.items[
				index * this.elements.numberOfVisibleItems +
					this.elements.numberOfVisibleItems -
					1
			]?.classList.add('last');
		} else if (this.activeGroup === this.totalNumberOfGroups) {
			// in the remainder group
			this.elements.items[
				this.size - this.elements.numberOfVisibleItems
			].classList.add('first');
			this.elements.items[this.size - 1]?.classList.add('last');
		}
	};

	swipeHandler = (event) => {
		// TOUCH events
		if (event.type === 'touchstart') {
			const initialX = event.touches[0].clientX;

			this.elements.scroller.addEventListener(
				'touchmove',
				debounce(
					(e) => {
						if (e.touches[0].clientX < initialX) {
							this.goNext();
						} else if (e.touches[0].clientX > initialX) {
							this.goPrevious();
						}
					},
					10,
					true // the `true` param is to skip the goToElement method in the goNext/goPrevious
				),
				{ once: true }
			);
		}

		// DRAG events
		// WHEEL events
		// SORRY, no wheel nor drag events ...
		// it causes to many edge cases and overrides the user preferences
	};

	getElementsInVisibleGroup = () => {
		this.elements.visibleItems = [];
		let firstVisibleItem = 0;
		if (this.isLastGroup) {
			firstVisibleItem = this.size - this.elements.numberOfVisibleItems;
		} else {
			firstVisibleItem =
				(this.activeGroup - 1) * this.elements.numberOfVisibleItems;
		}

		for (
			let index = firstVisibleItem;
			index < firstVisibleItem + this.elements.numberOfVisibleItems;
			index++
		) {
			this.elements.visibleItems.push(this.elements.items[index]);
		}
	};

	setTabindex = () => {
		this.elements.items.forEach((item) => {
			item.setAttribute('aria-hidden', 'true');
			item.querySelectorAll('a, button').forEach((link) => {
				link.setAttribute('tabindex', '-1');
			});
		});
		this.elements.visibleItems.forEach((item) => {
			item.removeAttribute('aria-hidden');
			item.querySelectorAll('a, button').forEach((link) => {
				link.removeAttribute('tabindex');
			});
		});
	};

	destroy = (forced) => {
		this.unlisten();
		this.swimlaneHasBeenScrolledBefore = false;
		this.elements.scroller.querySelectorAll('.clone').forEach((clone) => {
			clone.remove();
			clone = null; // garbage collection
		});

		this.elements.previous.hidden = true;
		this.elements.root.classList.remove('is-activated');
		this.elements.scroller.removeAttribute('style');
		this.elements.root.querySelectorAll('x-swimlane__item').forEach((item) => {
			item.removeAttribute('class');
		});

		this.hasPrependedGroup = false;
		this.isLastGroup = false;
		this.isFirstGroup = true;
		this.firstGroupNumber = 1;

		if (forced) {
			window.sessionStorage.removeItem(SWIMLANE_POSITION_STORAGE_KEY);
		}
	};
}
