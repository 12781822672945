export default class DropDown {
	constructor(element, onChange) {
		if (!element) throw Error('No dropdown element provided');

		this.element = element;

		this.config = {
			valueSelector: '[js-element~="customSelectValue"]',
			optionSelector: '[js-element~="customSelectOption"]',
			wrapperSelector: '[js-element~="customSelectWrapper"]',
		};

		this.currentValue = null;
		this.options = this.element.querySelectorAll(this.config.optionSelector);
		this.val = this.element.querySelector(this.config.valueSelector);
		this.wrapper = this.element.querySelector(this.config.wrapperSelector);
		this.onChange = onChange ? onChange : () => {};
		this.focusedOption = 0;

		this.init();
	}

	init = () => {
		// make it a11y,
		// ⚠️ don't set role="combobox" on this.val because it's not that type of dropdown
		//
		this.val.setAttribute('aria-expanded', 'false');
		this.val.setAttribute('aria-controls', this.wrapper.id);
		this.val.setAttribute('aria-haspopup', 'listbox');
		this.wrapper.setAttribute('role', 'listbox');
		this.wrapper.setAttribute('tabindex', '-1');
		this.wrapper.setAttribute('aria-hidden', 'true');
		this.options.forEach((option, index) => {
			option.setAttribute('role', 'option');
			option.setAttribute('tabindex', '-1');
			if (option.id === '') option.id = `${this.wrapper.id}-${index}`;

			if (option.getAttribute('aria-selected') === 'true') {
				this.focusedOption = index;
			}
		});
		this.currentValue =
			this.options[this.focusedOption]?.value ||
			this.options[this.focusedOption]?.href;
		this.wrapper.setAttribute(
			'aria-activedescendant',
			this.options[this.focusedOption].id
		);

		this.addEventListeners();
	};

	addEventListeners = () => {
		this.val.addEventListener('click', (e) => this.onSelectState(e));
		this.options.forEach((option) => {
			option.addEventListener('click', (e) => this.onSelectChange(e), false);
			option.addEventListener('keydown', (e) => this.onSelectChange(e), false);
		});
	};

	onSelectState = (e) => {
		// close it
		if (this.val.getAttribute('aria-expanded') === 'true') {
			this.closeDropdown(e);
		} else {
			// open it
			this.val.setAttribute('aria-expanded', 'true');
			this.options[this.focusedOption].focus();
			this.wrapper.removeAttribute('aria-hidden');
			document.addEventListener('click', this.globalClose, false);
			document.addEventListener('keydown', this.keydownHandler);
		}
	};

	onSelectChange = (e) => {
		const keys = ['Enter', ' '];
		const types = ['click', 'change'];

		if (keys.includes(e.key) || types.includes(e.type)) {
			const currentTarget = e.currentTarget;
			const newValue = currentTarget?.href || currentTarget?.value;

			// if you picked the same value again, do nothing and close the dropdown
			if (this.currentValue === newValue) {
				this.closeDropdown(e);
				return;
			}

			const newLabel =
				currentTarget?.textContent || currentTarget?.dataset.label;

			this.options.forEach((option) => {
				option.setAttribute('aria-selected', 'false');
			});
			this.val.querySelector('span').textContent = newLabel;
			currentTarget?.setAttribute('aria-selected', 'true');

			this.focusedOption = Array.from(this.options).indexOf(currentTarget);
			this.currentValue = newValue;
			// Update the aria-activedescendant listbox on the value to the ID of the option the user just interacted with
			this.wrapper.setAttribute(
				'aria-activedescendant',
				this.options[this.focusedOption]?.id
			);

			this.closeDropdown(e);

			try {
				this.onChange(e);
			} catch (error) {
				throw new Error(
					`Error in onChange callback for DropDown: ${error.message}`,
					error
				);
			}
		}
	};

	globalClose = (e) => {
		// skip if you click on the button or its (grand) children
		if (
			e.target === this.val ||
			Array.from(this.val.querySelectorAll('*')).indexOf(e.target) > -1
		)
			return;

		this.closeDropdown(e);
	};

	keydownHandler = (e) => {
		if (e.key === 'Escape' || e.key === 'Esc') {
			this.closeDropdown(e);
			return;
		}

		const keys = ['ArrowDown', 'ArrowUp', 'Tab', 'Home', 'End'];

		if (keys.includes(e.key)) {
			// disabling the default behaviour
			// - scrolling of the browser when using arrow keys
			// - tabbing to the next element
			// - page scrolling with Home and End
			e.preventDefault();

			if (e.key === 'ArrowDown') this.focusedOption++;
			if (e.key === 'ArrowUp') this.focusedOption--;
			if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
				// loop thru to the last option
				if (this.focusedOption < 0)
					this.focusedOption = this.options.length - 1;
				// loop thru to the first option
				if (this.focusedOption >= this.options.length) this.focusedOption = 0;
			}

			// pressing Tab will bring you to the first option
			if (e.key === 'Tab') {
				this.focusedOption = 0;
			}

			if (e.key === 'Home') {
				this.focusedOption = 0;
			}

			if (e.key === 'End') {
				this.focusedOption = this.options.length - 1;
			}

			// highlight the chosen option
			this.options[this.focusedOption].focus();
		}
	};

	closeDropdown = (e) => {
		this.focusHandler(e);
		this.val.setAttribute('aria-expanded', 'false');
		this.wrapper.setAttribute('aria-hidden', 'true');
		document.removeEventListener('keydown', this.keydownHandler);
		document.removeEventListener('click', this.globalClose, false);
	};

	focusHandler = (e) => {
		if (!e) throw new Error('No event provided to focusHandler');
		// this only handles the focus when:
		//  - you clicked outside the dropdown
		//  - you clicked on the value
		//  - you clicked on an option that was already selected
		//  - you pressed the Escape key
		if (Array.from(this.options).indexOf(e.currentTarget) === -1) {
			this.val.focus();
		}
		// when you clicked on a new option, the focus will be handle by the callback
		// => the module that responds to that callback
	};
}
