let code, keys, overlay, audio, styleBlock;

function init() {
	code = [
		'ArrowUp',
		'ArrowUp',
		'ArrowDown',
		'ArrowDown',
		'ArrowLeft',
		'ArrowRight',
		'ArrowLeft',
		'ArrowRight',
		'b',
		'a',
	];
	keys = [];

	if (
		window.location.hostname === 'www.vtmgo.be' ||
		window.location.hostname === 'localhost'
	) {
		document.addEventListener('keyup', konamiListener, { passive: true });
	}
}

function konamiListener(e) {
	keys.push(e.key);

	if (keys.toString().indexOf(code) >= 0) {
		document.removeEventListener('keyup', konamiListener);
		credits();
	}
}

function credits() {
	overlay = document.createElement('div');
	audio = document.createElement('audio');

	let list = document.createElement('dl'),
		tmpDt,
		tmpDd,
		crew = [
			{
				title: 'Streaming Area presents',
				name: [
					document
						.querySelector('meta[name="apple-mobile-web-app-title"]')
						.getAttribute('content'),
				],
				big: 'true',
			},
			{
				title: 'Stunt team' /* Njetflix */,
				name: [
					'Alex',
					'Bart',
					'Davy',
					'Francis',
					'Geert',
					'Gregory',
					'Hans',
					'Joris',
					'Maarten',
					'Nicolas',
					'Rik',
					'Raf',
					'Stefan',
					'Tim',
					'William',
					'Yarl',
				],
			},
			{
				title: 'Cinematographers' /* DeVideotheek */,
				name: ['Cedric', 'Jan', 'Jeroen', 'Jo', 'Matthias', 'Seb', 'Senne'],
			},
			{
				title: 'Editors' /* Watchmen */,
				name: [
					'Beatrijs',
					'Birgit',
					'Elias',
					'Harald',
					'Kenneth',
					'Wim',
					'Yannick',
				],
			},
			{
				title: 'Title design' /* Hollywood */,
				name: [
					'Bert',
					'Bart',
					'Cindy',
					'Peter',
					'Pieter',
					'Thomas',
					'Walter',
					'Yannick',
				],
			},
			{
				title: 'Costume design' /* Avidci */,
				name: ['Damilya', 'Dieter', 'Giel', 'Lineke', 'Mikhail', 'Tom'],
			},
			{
				title: 'Visual FX' /* UX - UI - design */,
				name: ['Michaël', 'Gwendolyn'],
			},
			{
				title: 'Assistant producers',
				name: ['Marnik', 'Wannes'],
			},
			{
				title: 'Screenplay by',
				name: ['Ellen', 'Josephine'],
			},
			{
				title: 'Post-production',
				name: ['Amber', 'Elien', 'Hannelore', 'Lisa', 'Tessa', 'Zoé'],
			},
			{
				title: 'Writers',
				name: ['Katrien', 'Kim', 'Kristina', 'Pieter', 'Riet'],
			},
			{
				title: 'Special FX',
				name: ['Daan', 'Ellen', 'Jan', 'Pieter', 'Tabitha'],
			},
			{
				title: 'Casting director',
				name: ['Karen'],
			},
			{
				title: 'Producers',
				name: ['Sven', 'Yves'],
			},
			{
				title: 'Executive producers',
				name: ['Calogero', 'Jeroen'],
			},
			{
				title: 'Shot on location in Vilvoorde',
				name: [],
			},
			{
				title: 'No roadmaps were harmed in the making of these credits',
				name: [],
			},
			{
				title: 'Former contributors',
				name: [
					'Ashwin',
					'Bojan',
					'Cedric',
					'Charles',
					'Daan',
					'Emma',
					'Frederik',
					'Gert-Jan',
					'Glenn',
					'Goedele',
					'Greg',
					'Hendrik',
					'Johan',
					'Jonas',
					'Lewie',
					'Maarten',
					'Matthias',
					'Pablo',
					'Renaud',
					'Sam',
					'Tanguy',
					'Tim',
					'Tristan',
					'Yannick',
				],
				small: 'true',
			},
		],
		i = 0,
		l = crew.length,
		staticUrl = staticURL();

	styleBlock = document.createElement('style');
	styleBlock.textContent = `
	.crawler {
		position: fixed;
		z-index: 1000;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgb(0, 0, 0, 0.8);
		opacity: 0;
		transition: opacity 2s linear;
	}

	.crawler__credits {
		margin: 0;
		padding: 0;
		text-align: center;
		color: #fff;
		transform: translateY(100vh);
		animation: 40s linear 2s forwards crawler;
	}

	.crawler__credits dt {
		font-weight: bold;
		font-size: 1rem;
		text-transform: uppercase;
	}

	.crawler__credits dt.big {
		font-size: 2rem;
	}

	.crawler__credits dt.small {
		font-size: 0.75rem;
	}

	.crawler__credits dd {
		margin: 0 0 4em;
		font-size: 1.5rem
	}

	.crawler__credits dd.big {
		font-size: 3rem;
	}

	.crawler__credits dd.small {
		font-size: 0.875rem;
	}

	@keyframes crawler {
		0% {
			transform: translateY(100vh)
		}
		100% {
			transform: translateY(-100%)
		}
	}`;

	document.head.appendChild(styleBlock);

	overlay.classList.add('crawler');
	list.classList.add('crawler__credits');

	for (i = 0; i < l; i++) {
		tmpDt = document.createElement('dt');
		tmpDd = document.createElement('dd');
		tmpDt.textContent = crew[i].title;
		if (crew[i].name.length) {
			tmpDd.textContent = crew[i].name.join(', ');
		}

		if (crew[i].big) {
			tmpDt.classList.add('big');
			tmpDd.classList.add('big');
		}

		if (crew[i].small) {
			tmpDt.classList.add('small');
			tmpDd.classList.add('small');
		}

		list.appendChild(tmpDt);
		list.appendChild(tmpDd);
	}

	overlay.append(list);
	document.body.appendChild(overlay);

	window.setTimeout(function () {
		overlay.style.opacity = '.9';
	}, 10);

	overlay.addEventListener(
		'transitionend',
		() => {
			audio.setAttribute('src', staticUrl + '/img/general/konami.mp3');
			audio.setAttribute('autoplay', true);
		},
		{ once: true, passive: true }
	);

	document.addEventListener(
		'keydown',
		(e) => {
			if (e.key === 'Escape' || e.key === 'Esc') {
				closeCredits();
			}
		},
		{ once: true, passive: true }
	);

	list.addEventListener('animationend', closeCredits, {
		once: true,
		passive: true,
	});

	window.dataLayer = window.dataLayer || [];

	window.dataLayer.push({
		event: 'product_impression',
		list: {
			id: 'konami_code',
			name: 'Konami code',
			provider: 'App',
		},
	});
}

function staticURL() {
	let styleUrlArr = document
		.querySelectorAll('[rel="stylesheet"]')[0]
		.getAttribute('href')
		.split('/');
	styleUrlArr.pop();
	styleUrlArr.pop();
	return styleUrlArr.join('/');
}

function closeCredits() {
	overlay.addEventListener(
		'transitionend',
		() => {
			overlay.remove();
			overlay = null;
			init();
		},
		{ once: true, passive: true }
	);
	overlay.style.opacity = '0';
	overlay.style.transitionDuration = '600ms';
	audio.pause();
	audio = null;
	styleBlock.remove();
}

export default {
	init,
};
