/*
 * Using the native <dialog> for browsers that support it and
 * fallback to <div class="modal" role="dialog"> for older
 * browsers
 * v3.2.0
 * Inspired by https://web.dev/building-a-dialog-component/
 *
 * Modals are a type of dialog that appears in front of app content to provide critical information or ask for a decision. They also block interactions with the app content until the modal is closed ➡️ aria-modal="true"
 *
 * This Class allows you to create modals and alertdialogs
 *
 * Dialogs that allow users to interact with content or system functionality are typically role="dialog" + aria-modal="false" ➡️ See dialog.js
 */

import Backdrop from '../backdrop/backdrop';

window.App = window.App || {};
window.App.modalQueue = false;

const BODY_MODAL_CLASS = 'has-modal';
const prefersReducedMotion =
	window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
	window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;
// custom events to be added to <dialog>
const modalClosingEvent = new Event('modalClosing');
const modalClosedEvent = new Event('modalClosed');
const modalOpeningEvent = new Event('modalOpening');
const modalOpenedEvent = new Event('modalOpened');

export default class Modal {
	constructor(id, options, { onClose, onOpen } = {}, modifiers, trigger) {
		// check if there's already a modal in the queue
		if (window.App?.modalQueue) {
			return;
		} else {
			window.App.modalQueue = true;
		}

		this.closeButton = true;

		if (options && typeof options === 'object') {
			this.message = options.message ?? null;
			this.closeButton = options.closeButton ?? this.closeButton; // for Modals, not alertdialog
			this.alertDialog = options.alertDialog ?? false;
			this.icon = options.icon ?? 'info'; // for alertdialogs
		}

		if (!this.message) throw Error('No content provided for the modal');
		this.id = id || 'GeneralModal';
		this.modal = null;
		this.modalBackdrop = null;

		this.trigger = trigger;

		this.isOpen = false;
		this.modifiers = modifiers; // array of additional class names
		this.supportsNativeDialogElement = false;
		// for the focus trap
		this.keyDownHandler = this.modalKeyDownHandler.bind(this);
		this.firstFocusableEl = null;
		this.lastFocusableEl = null;

		// Default callbacks, can be overwritten
		this.onClose = onClose ? onClose : () => {};
		this.onOpen = onOpen ? onOpen : () => {};

		this.checkDialogElementCompatibility();
		this.createModal();
	}

	close() {
		this.closeModal();
	}

	open() {
		this.showModal();
	}

	checkDialogElementCompatibility() {
		if (typeof HTMLDialogElement === 'function') {
			this.supportsNativeDialogElement = true;
		}
	}

	createModal() {
		const modal = this.supportsNativeDialogElement
			? document.createElement('dialog')
			: document.createElement('div');
		modal.id = this.id;
		if (this.alertDialog) {
			modal.setAttribute('role', 'alertdialog');
		} else {
			modal.setAttribute('role', 'dialog');
		}
		modal.inert = true;
		modal.setAttribute('aria-modal', 'true');
		modal.setAttribute('data-nosnippet', 'true');

		if (this.alertDialog) {
			modal.classList.add('alert', 'alert--toast');
		} else {
			modal.classList.add('modal', 'is-loading');
		}

		if (this.modifiers && Array.isArray(this.modifiers)) {
			this.modifiers.forEach((className) => {
				modal.classList.add(className);
			});
		}

		// not all modals need a close button in the upper right corner
		// but if they do, we add it here
		if (this.closeButton && !this.alertDialog) {
			const closeButton = document.createElement('button');
			closeButton.classList.add(
				'modal__close',
				'btn',
				'btn--secondary',
				'btn--icon'
			);
			closeButton.setAttribute('type', 'button');
			closeButton.setAttribute('js-element', 'modalClose');
			closeButton.setAttribute('aria-label', window.i18n?.close);
			closeButton.innerHTML = `<span class="btn__icon" aria-hidden="true"><svg class="icon" aria-hidden="true"><use xlink:href="${window.App.icons}#close"></use></svg></span>`;
			modal.appendChild(closeButton);
		}

		// alert dialogs have a different structure
		if (this.alertDialog) {
			// if it's a DocumentFragment (from a <template>), append it
			if (typeof this.message === 'object') {
				modal.appendChild(this.message);
			} else if (typeof this.message === 'string') {
				const html = document
					.getElementById('alertDialogTemplate')
					.content.cloneNode(true);

				if (!html) {
					throw new Error('No template found for the alert dialog');
				} else {
					modal.appendChild(html);
				}
				modal
					.querySelector('[js-element~="alertIcon"] svg use')
					.setAttribute('xlink:href', `${window.App.icons}#${this.icon}`);

				const alertBody = modal.querySelector('[js-element~="alertBody"]');
				alertBody.id = `${this.id}Message`;
				alertBody.textContent = this.message;
			}

			modal.setAttribute('aria-labelledby', `${this.id}Message`);
		} else {
			// regular modal / dialog
			if (typeof this.message === 'object') {
				modal.appendChild(this.message);
			} else {
				throw new Error('No Modal message as HTML provided');
			}

			const title = modal.querySelector('.modal__title');
			if (title) {
				if (!title.id) title.id = `${this.id}Title`;
				modal.setAttribute('aria-labelledby', `${this.id}Title`);
			}
			const message = modal.querySelector('.modal__text');
			if (message) {
				if (!message.id) message.id = `${this.id}Description`;
				modal.setAttribute('aria-describedby', `${this.id}Description`);
			}
		}

		if (!this.supportsNativeDialogElement) this.createCustomBackdrop();
		document.body.appendChild(modal);
		this.modal = modal;
		this.trapFocus();
		this.showModal();
		this.addModalCloseEventListeners();
	}

	createCustomBackdrop() {
		this.modalBackdrop = new Backdrop({
			classNames: ['modal__backdrop'],
		});
	}

	showModal() {
		this.modal.dispatchEvent(modalOpeningEvent);
		document.body.classList.add(BODY_MODAL_CLASS);
		this.modal.classList.remove('is-loading');

		if (!prefersReducedMotion) {
			this.modal.addEventListener(
				'animationend',
				() => {
					this.modal.dispatchEvent(modalOpenedEvent);
				},
				{ once: true }
			);
		}

		if (this.supportsNativeDialogElement) {
			this.modal.showModal(); // native <dialog> show method
		} else {
			this.modal.setAttribute('open', '');
			[...document.body.children].forEach((child) => {
				if (
					!child.classList.contains('modal') &&
					!child.classList.contains('modal__backdrop') &&
					child.tagName !== 'SCRIPT' &&
					child.tagName !== 'TEMPLATE'
				) {
					child.setAttribute('aria-hidden', 'true');
					child.inert = true;
				}
			});
		}

		this.modal.inert = false;

		if (prefersReducedMotion) {
			this.modal.dispatchEvent(modalOpenedEvent);
		}

		this.isOpen = true;

		// set focus
		document.activeElement.blur();
		const focusTarget = this.modal.querySelector('[autofocus]');
		if (focusTarget) {
			focusTarget.focus();
		} else if (this.closeButton && !this.alertDialog) {
			this.modal.querySelector('[js-element~="modalClose"]').focus();
		} else if (this.alertDialog) {
			this.modal.querySelector('[js-element~="alertClose"]').focus();
		} else {
			this.firstFocusableEl.focus();
		}

		try {
			this.onOpen();
		} catch (error) {
			throw new Error(
				`Error in onOpen callback for Modal: ${error.message}`,
				error
			);
		}
	}

	// click outside the modal handler
	lightDismiss({ target: element }) {
		if (!this.supportsNativeDialogElement) {
			if (element.className === 'modal__backdrop') this.closeModal();
		} else {
			this.closeModal();
		}
	}

	closeModal() {
		if (!this.modal) return;
		this.modal.removeEventListener('keydown', this.keyDownHandler);
		if (this.supportsNativeDialogElement) {
			this.modal.close();
		} else {
			[...document.body.children].forEach((child) => {
				if (
					child !== this.modal &&
					!child.classList.contains('modal__backdrop') &&
					child.tagName !== 'SCRIPT'
				) {
					child.removeAttribute('aria-hidden');
					child.inert = false;
				}
			});
		}

		window.dispatchEvent(modalClosingEvent);
		this.modal.inert = true;
		this.modal.setAttribute('aria-hidden', 'true');

		if (!prefersReducedMotion) {
			const d = this.modal;
			d.addEventListener(
				'animationend',
				function () {
					window.dispatchEvent(modalClosedEvent);
					d.remove();
				},
				{ once: true }
			);
		}

		this.modal.removeAttribute('open');
		document.body.classList.remove(BODY_MODAL_CLASS);

		if (prefersReducedMotion) {
			window.dispatchEvent(modalClosedEvent);
			this.modal.remove();
		}

		try {
			this.onClose();
		} catch (error) {
			throw new Error(
				`Error in onClose callback for Modal: ${error.message}`,
				error
			);
		}

		// After the dialog is dismissed, keyboard focus should be moved back to
		// where it was before it moved into the dialog. Otherwise the focus can be
		// dropped to the beginning of the page.
		if (this.trigger) {
			this.trigger.focus();
		} else {
			document.body.focus();
		}

		// remove the modal from the queue
		window.App.modalQueue = false;

		this.modalBackdrop?.removeBackdrop();
		// garbage collection
		this.modal = null;
	}

	addModalCloseEventListeners() {
		if (this.supportsNativeDialogElement) {
			this.modal.addEventListener('click', (e) => {
				if (e.target.nodeName === 'DIALOG') {
					this.lightDismiss(e);
				}
			});
		} else {
			this.modalBackdrop?.getElement()?.addEventListener(
				'click',
				() => {
					this.lightDismiss({ target: this.modalBackdrop.getElement() });
				},
				{ once: true }
			);
		}

		this.modal.addEventListener('keydown', this.keyDownHandler);

		if (!this.alertDialog) {
			this.modal
				.querySelectorAll('[js-element~="modalClose"]')
				.forEach((trigger) => {
					trigger.addEventListener(
						'click',
						() => {
							this.closeModal();
						},
						{ once: true }
					);
				});
		}
		if (this.alertDialog) {
			this.modal
				.querySelectorAll('[js-element~="alertClose"]')
				.forEach((trigger) => {
					trigger.addEventListener(
						'click',
						() => {
							this.closeModal();
						},
						{ once: true }
					);
				});
		}
	}

	modalKeyDownHandler(event) {
		if (event.key === 'Escape' || event.key === 'Esc') {
			this.closeModal();
		}

		if (
			event.key === 'Tab' &&
			event.shiftKey &&
			document.activeElement === this.firstFocusableEl
		) {
			this.lastFocusableEl.focus();
			event.preventDefault();
		} else if (
			event.key === 'Tab' &&
			document.activeElement === this.lastFocusableEl
		) {
			this.firstFocusableEl.focus();
			event.preventDefault();
		}
	}

	// ♿️ a11y requirement. While the modal is open, trap its focus to the modal &
	// block any focus outside of the modal. Even block interaction with the
	// browser UI
	trapFocus() {
		const focusableElements = this.modal.querySelectorAll(
			'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
		);

		// Save first and last focusable elements
		this.firstFocusableEl = focusableElements[0];
		this.lastFocusableEl = focusableElements[focusableElements.length - 1];
	}
}
