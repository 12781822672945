import Modal from '../../classes/modal/modal';

import profilesTracking from './profiles.tracking';

let form, button;

function init() {
	button = document.querySelector('[js-module~="profileDelete"]');

	if (!button) return;
	form = button.form;

	button.addEventListener('click', createModal);
}

function createModal(e) {
	// inside the function to fetch the template every time
	const html = document
		.getElementById('deleteProfileModalTemplate')
		?.content.cloneNode(true);

	if (!html) return;

	new Modal(
		'DeleteProfileModal',
		{
			message: html,
		},
		{
			onOpen: confirm,
		},
		null,
		e.currentTarget
	);
	e.preventDefault();
}

function confirm() {
	profilesTracking.init();

	const deleteModalButton = window.DeleteProfileModal.querySelector(
		'[js-element~="profileDeleteConfirm"]'
	);

	deleteModalButton.addEventListener(
		'click',
		() => {
			form.submit();
		},
		false
	);
}

export default {
	init,
	confirm,
};
