const tabPanelSwitchEvent = new Event('tabPanelSwitch'); // only needed for testing purposes
export default class Tabs {
	options = {
		id: null,
		navSelector: '[js-element~="tabList"]',
		tabsSelector: '[js-element~="tab"]',
		panelsSelector: '[js-element~="tabPanel"]',
	};

	constructor(element, config, onChange) {
		if (!element) throw Error('No Tabs element provided');

		this.element = element;
		this.options = { ...this.options, ...config };
		this.panels = this.element.querySelectorAll(this.options.panelsSelector);
		this.tabs = this.element.querySelectorAll(this.options.tabsSelector);
		this.tablist = this.element.querySelector(this.options.navSelector);
		this.clickHandler = this.tabsClickHandler.bind(this);
		this.keyDownHandler = this.tabsKeyDownHandler.bind(this);
		this.id = this.setId();
		this.activeTabIndex = 0;
		this.focusedTabIndex = 0;
		this.onChange = onChange ? onChange : () => {};

		this.process();
	}

	process() {
		this.tablist.setAttribute('aria-multiselectable', 'false');
		this.tablist.setAttribute('role', 'tablist');
		this.tablist.setAttribute('aria-orientation', 'horizontal');
		this.element.setAttribute('id', this.id);
		this.url = new URL(window.location.href);
		this.urlParams = this.url.searchParams;

		this.createTabs();

		this.createPanels();

		this.getInitialTab();

		this.addTabsEventListeners();
	}

	createTabs() {
		this.tabs.forEach((tab, index) => {
			tab.setAttribute('role', 'tab');
			tab.setAttribute('aria-controls', `TabPanel-${this.id}-${index + 1}`);
			tab.setAttribute('aria-setsize', this.tabs.length);
			tab.setAttribute('aria-posinset', index + 1);
			tab.id = `TabLabel-${this.id}-${index + 1}`;
			this.setDynamicTabAttributes(tab);
		});
	}

	setDynamicTabAttributes(tab, force = false) {
		if (!force) {
			if (tab.ariaSelected === 'true') return;
		}

		tab.setAttribute('aria-selected', 'false');
		tab.setAttribute('aria-expanded', 'false');
		tab.setAttribute('tabindex', '-1');
	}

	setActiveTab(el) {
		if (!el) return;
		el.setAttribute('aria-selected', 'true');
		el.setAttribute('aria-expanded', 'true');
		el.removeAttribute('tabindex');
		this.activeTabIndex = new Number(el.getAttribute('aria-posinset')) - 1;
		this.focusedTabIndex = this.activeTabIndex;
	}

	createPanels() {
		if (this.panels.length === 0) return;

		for (let i = 0; i < this.panels.length; i++) {
			const panel = this.panels[i];
			panel.setAttribute('role', 'tabpanel');

			if (this.panels.length === 1) {
				panel.setAttribute(
					'aria-labelledby',
					`TabLabel-${this.id}-${this.activeTabIndex + 1}`
				);
				panel.id = `TabPanel-${this.id}-${this.activeTabIndex + 1}`;
			} else {
				panel.setAttribute('aria-labelledby', `TabLabel-${this.id}-${i + 1}`);
				panel.id = `TabPanel-${this.id}-${i + 1}`;
			}

			this.setDynamicPanelAttributes(panel);
		}
	}

	setDynamicPanelAttributes(el) {
		el.setAttribute('aria-hidden', 'true');
		el.hidden = true;
		el.setAttribute('tabindex', '-1');
	}

	setActivePanel(el) {
		if (!el) return;

		el.removeAttribute('aria-hidden');
		el.hidden = false;
		// When the tabpanel does not contain any focusable elements or the
		// first element with content is not focusable, the tabpanel should
		// set tabindex="0" to include it in the tab sequence of the page.
		const focusableElements = el.querySelectorAll(
			'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
		);
		if (focusableElements.length === 0) {
			el.setAttribute('tabindex', '0');
		}

		el.dispatchEvent(tabPanelSwitchEvent);
	}

	addTabsEventListeners() {
		this.tabs.forEach((tab) => {
			tab.addEventListener('click', this.clickHandler);
		});

		// for keyboards: keyboard navigation , enter & spacebar
		this.tabs.forEach((tab) => {
			tab.addEventListener('keydown', this.keyDownHandler);
		});
	}

	removeTabsEventListeners() {
		this.tabs.forEach((tab) => {
			tab.removeEventListener('click', this.clickHandler);
		});
		this.tablist.removeEventListener('keydown', this.keyDownHandler);
	}

	tabsClickHandler(event) {
		if (event.currentTarget.getAttribute('aria-pressed') === 'true') return;

		this.tabChange(event);
		if (event.currentTarget.tagName === 'A') {
			event.preventDefault();
		}
	}

	tabsKeyDownHandler(e) {
		let keys = ['ArrowRight', 'ArrowLeft', 'Home', 'End'];
		let tabChangeKey = ['Enter', ' '];

		if (keys.includes(e.key)) {
			// disabling the default behaviour
			// - scrolling of the browser when using arrow keys
			// - tabbing to the next element
			// - page scrolling with Home and End
			e.preventDefault();

			// move left or right
			if (e.key === 'ArrowRight') this.focusedTabIndex++;
			if (e.key === 'ArrowLeft') this.focusedTabIndex--;

			// loop thru to the first option
			if (this.focusedTabIndex >= this.tabs.length) this.focusedTabIndex = 0;
			// loop thru to the last option
			if (this.focusedTabIndex < 0) this.focusedTabIndex = this.tabs.length - 1;

			if (e.key === 'Home') {
				this.focusedTabIndex = 0;
			}

			if (e.key === 'End') {
				this.focusedTabIndex = this.tabs.length - 1;
			}

			// highlight the chosen Tab
			this.tabs[this.focusedTabIndex].focus();
		}

		if (tabChangeKey.includes(e.key)) {
			this.tabChange(e);
			e.preventDefault(); // for the Spacebar (to avoid page scrolling)
		}
	}

	tabChange(event) {
		this.urlParams.set('tab', event.currentTarget.dataset.label);

		// update the url without reloading the page or adding to the history
		window.history.replaceState(null, '', this.url.toString());

		this.tabs.forEach((tab) => {
			this.setDynamicTabAttributes(tab, true);
		});

		this.panels.forEach((panel) => {
			this.setDynamicPanelAttributes(panel);
		});

		this.setActiveTab(event.currentTarget);

		let panel = null;
		// in the tvguide, there's only 1 panel that gets replaced dynamically
		if (this.panels.length === 1) {
			panel = this.panels[0];
		} else {
			panel = document.getElementById(
				event.currentTarget.getAttribute('aria-controls')
			);
		}
		this.setActivePanel(panel);

		// if the click was initiated by a keyboard event (enter of spacebar), focus the panel
		if (event.key === 'Enter' || event.key === ' ') {
			panel.focus();
		}

		try {
			this.onChange(event);
		} catch (error) {
			throw new Error(
				`Error in onChange callback for Tabs: ${error.message}`,
				error
			);
		}
	}

	getInitialTab() {
		if (window.location.search.length > 0) {
			const urlTab = this.urlParams.get('tab');
			if (urlTab) {
				const { urlTabElement, index } = this.tabMatcher(urlTab);

				if (urlTabElement) {
					this.tabs.forEach((tab) => {
						this.setDynamicTabAttributes(tab, true);
					});
					this.setActiveTab(urlTabElement);
					this.setActivePanel(this.panels[index]);

					try {
						this.onChange(urlTabElement);
					} catch (error) {
						throw new Error(
							`Error in onChange callback for Tabs: ${error.message}`,
							error
						);
					}
					return;
				}
			}
		}

		this.setActiveTab(this.getActiveTabElement());
		this.setActivePanel(this.panels[0]);
	}

	getActiveTabElement() {
		const els = Array.from(this.tabs);
		const activeTabElement =
			els.find((el) => el.ariaSelected === 'true') || els[0];

		return activeTabElement;
	}

	tabMatcher(urlTab) {
		const tab = Array.from(this.tabs).find(
			(tab) => tab.dataset.label.toLowerCase() === urlTab.toLowerCase()
		);

		const index = Array.from(this.tabs).indexOf(tab);
		if (!tab || index === -1) return { urlTabElement: this.tabs[0], index: 0 };
		return { urlTabElement: tab, index: index };
	}

	setId() {
		if (this.options.id) {
			return this.options.id;
		} else if (this.element.id) {
			return this.element.id;
		} else {
			return 'Tabs-' + this.getRandomInt(1000);
		}
	}

	getRandomInt(max) {
		return Math.floor(Math.random() * Math.floor(max));
	}

	destroy() {
		this.removeTabsEventListeners();

		this.tablist.removeAttribute('aria-multiselectable');
		this.tablist.removeAttribute('role');

		this.tabs.forEach((tab) => {
			tab.removeAttribute('role');
			tab.removeAttribute('aria-controls');
			tab.removeAttribute('aria-setsize');
			tab.removeAttribute('aria-posinset');
			tab.removeAttribute('aria-selected');
			tab.removeAttribute('aria-expanded');
			tab.removeAttribute('tabindex');
			tab.blur();
		});

		this.panels.forEach((panel) => {
			panel.removeAttribute('role');
			panel.removeAttribute('aria-labelledby');
			panel.removeAttribute('aria-hidden');
			panel.removeAttribute('tabindex');
			panel.hidden = false;
		});
	}
}
