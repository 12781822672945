function init(element) {
	if (!element) return;

	window.dataLayer = window.dataLayer || [];

	if (element.getAttribute('lfvp-tracking') === 'cta') {
		const name = element.dataset.name;
		window.dataLayer.push({
			event: 'call_to_action_impression',
			call_to_action: {
				name: name,
				type: 'button',
			},
		});

		element.addEventListener(
			'click',
			() => {
				window.dataLayer.push({
					event: 'call_to_action_click',
					call_to_action: {
						name: name,
						type: 'button',
					},
				});
			},
			{ passive: true }
		);
	} else {
		generateImpressionData(element);
	}
}

export function generateImpressionData(element) {
	const { listData, searchData, assetId } = generateListData(
		element.dataset.tracking
	);
	const productImpressionData = {
		event: 'product_impression',
		list: listData,
	};

	if (searchData) {
		productImpressionData['search_term'] = searchData.term ?? '';
		productImpressionData['search_results'] = searchData.results;
	}

	// send asset_id if it's available (when it only has 1 item in the list, e.g. top banner)
	if (assetId) {
		productImpressionData.asset_id = assetId;
	}

	const items = element.querySelectorAll(
		'*:not(.clone) [js-element~="tracking"]'
	);

	items.forEach((item) => {
		const itemData = generateItemData(item.dataset.tracking);

		item.addEventListener(
			'click',
			() => {
				// don't send the product click if we're in edit mode
				if (document.body.classList.contains('is-editing-teasers')) return;

				reportProductClick(itemData, listData);
			},
			{
				passive: true,
			}
		);
	});

	window.dataLayer.push(productImpressionData);
}

export function generateListData(data) {
	const trackingData = JSON.parse(data);
	const metaData = window.App.metaData || trackingData; // either on the window object or in the tracking data itself

	const listData = {
		ab_group: metaData?.abGroup ?? null,
		id: trackingData.id,
		name: trackingData.name ?? trackingData.id, // sometimes the id is already self-explanatory, so no need to repeat it in the data
		orientation: trackingData.orientation ?? null,
		position: trackingData.position,
		provider: trackingData.provider ?? null,
		request_id: metaData?.requestId ?? null,
		routing_group: metaData?.routingGroup ?? null,
	};

	let searchData = null;
	if (trackingData.searchQuery) {
		searchData = {
			term: trackingData.searchQuery.term ?? null,
			results: trackingData.searchQuery.results ?? null,
		};
	}

	let assetId = null;
	if (trackingData.assetId) {
		assetId = trackingData.assetId;
	}

	return { listData, searchData, assetId };
}

export function generateItemData(data) {
	return JSON.parse(data);
}

export function reportProductClick(itemData, listData) {
	listData.asset_position = itemData.position ?? null;

	const clickData = {
		asset_id: itemData.assetId ?? null,
		event: 'product_click',
		list: listData,
	};

	if (itemData.notification_title)
		clickData.notification_title = itemData.notification_title;

	window.dataLayer.push(clickData);
}

function sendVisibleTeasersImpressionTracking(data) {
	const metaData = window.App.metaData || data.list; // either on the window object or in the tracking data itself
	// if we have tracking data, push it to the global array
	if (data) {
		data.teasers.forEach((teaser) => {
			let trackingObject = {
				contentId: teaser.assetId,
				position: teaser.position,
				listPosition: data.list.position,
				listId: data.list.id,
				listName: data.list.name,
				listRequestId: metaData.requestId,
			};

			window.dataLayer.teasers.push(trackingObject);
		});
	}
}

export default {
	init,
	sendVisibleTeasersImpressionTracking,
};
