let tiers = null;
let durationButtons = null;
let planButtons = null;
let selectedPlan = 'streamzbasic'; // streamzbasic, streamz, streamzplus
let selectedDuration = 'yearly';
let cols = null;

const promotions = new Map();
const suffix = ' *'; // adding an * (asterisk) to the price
// any promotions active? add them to the map
// promotions.set('yearly', ['streamz']);

const streamzPremiumPlusBasePrice = 20.95;
const streamzPremiumBasePrice = 12.95;
const streamzBasicBasePrice = 7.95;

const pricingTable = {
	monthly: [
		// streamzpremiumplus
		{
			discountedPrice: null,
			fullPrice: streamzPremiumPlusBasePrice,
		},
		// streamzpremium
		{
			discountedPrice: null,
			fullPrice: streamzPremiumBasePrice,
		},
		// streamzbasic
		{
			discountedPrice: null,
			fullPrice: streamzBasicBasePrice,
		},
	],
	'6monthly': [
		// 'streamzpremiumplus'
		{
			discountedPrice: 17.95,
			fullPrice: streamzPremiumPlusBasePrice,
		},
		// streamzpremium
		{
			discountedPrice: 10.95,
			fullPrice: streamzPremiumBasePrice,
		},
		// streamzbasic
		{
			discountedPrice: 6.95,
			fullPrice: streamzBasicBasePrice,
		},
	],
	yearly: [
		// streamzpremiumplus
		{
			discountedPrice: 15.95,
			fullPrice: streamzPremiumPlusBasePrice,
		},
		// streamzpremium
		{
			discountedPrice: 9.95,
			fullPrice: streamzPremiumBasePrice,
		},
		// streamzbasic
		{
			discountedPrice: 5.95,
			fullPrice: streamzBasicBasePrice,
		},
	],
};

function init() {
	tiers = document.querySelector('[js-module~="tiers"]');

	if (!tiers) return;

	durationButtons = tiers.querySelectorAll('[js-element~="tiersDuration"]');
	planButtons = tiers.querySelectorAll('[js-element~="tiersPlan"]');
	cols = tiers.querySelectorAll('[js-element~="tiersCol"]');

	durationButtons.forEach((duration) => {
		duration.addEventListener(
			'click',
			() => {
				clickHandler(duration);
			},
			{ passive: true }
		);
	});

	planButtons.forEach((plan) => {
		plan.addEventListener(
			'click',
			() => {
				clickHandler(plan);
			},
			{ passive: true }
		);
	});
}

function clickHandler(el) {
	if (el.getAttribute('js-element') === 'tiersDuration') {
		durationButtons.forEach((duration) => {
			duration.removeAttribute('aria-current');
		});
		selectedDuration = el.dataset.duration;
	} else {
		planButtons.forEach((plan) => {
			plan.removeAttribute('aria-current');
			plan
				.querySelector('[js-element~="tiersCheckbox"] svg use')
				.setAttribute('xlink:href', `${window.App.icons}#checkCircleOff`);
		});
		selectedPlan = el.dataset.plan;
		cols.forEach((col) => {
			col.classList.remove('is-active');
		});

		const colsArray = Array.from(cols);
		const c = colsArray.find((col) => col.dataset.plan === selectedPlan);
		c.classList.add('is-active');

		el.querySelector('[js-element~="tiersCheckbox"] svg use').setAttribute(
			'xlink:href',
			`${window.App.icons}#checkCircleOn`
		);
	}

	tiers.querySelectorAll('[js-element~="tiersPrices"] td').forEach((price) => {
		if (promotions.has(selectedDuration)) {
			promotions.get(selectedDuration).forEach((plan) => {
				if (
					price.dataset.plan === plan &&
					!price.classList.contains('is-promo')
				) {
					price.classList.add('is-promo');
				}
			});
		} else {
			if (price.classList.contains('is-promo')) {
				price.classList.remove('is-promo');
			}
		}
	});

	el.setAttribute('aria-current', 'true');
	setPricing();
	setOnboardingUrl();
}

function setPricing() {
	const discountPrice = tiers.querySelectorAll(
		'[js-element~="tiersDiscountPrice"]'
	);
	const fullPrice = tiers.querySelectorAll('[js-element~="tiersFullPrice"]');

	discountPrice.forEach((price, index) => {
		const amount = pricingTable[selectedDuration][index].discountedPrice;
		if (typeof amount === 'number') {
			price.hidden = false;
			price.innerHTML = `${new Intl.NumberFormat('nl-BE', {
				style: 'currency',
				currency: 'EUR',
			}).format(amount)}${suffix ? suffix : ''}`;
		} else {
			price.hidden = true;
		}
	});
	fullPrice.forEach((price, index) => {
		const amount = pricingTable[selectedDuration][index].fullPrice;
		let priceString = null;
		if (typeof amount === 'number') {
			priceString = new Intl.NumberFormat('nl-BE', {
				style: 'currency',
				currency: 'EUR',
			}).format(amount);
		}
		// if there's a discounted price, we need to mark the full price as obsolete
		if (pricingTable[selectedDuration][index].discountedPrice) {
			if (!price.classList.contains('has-discount')) {
				price.classList.add('has-discount');
			}
			price.children[0].hidden = false; // make the <span>was</span> accessible
			price.children[1].innerHTML = priceString;
		} else {
			price.children[0].hidden = true; // hide the <span>was</span>
			price.classList.remove('has-discount');
			price.children[1].innerHTML = `${priceString}${suffix ? suffix : ''}`;
		}
	});
}

function setOnboardingUrl() {
	const onboardingButton = document.querySelector(
		'[js-element~="tiersOnboarding"]'
	);

	const onboardingUrl = new URL(onboardingButton.href);
	const params = onboardingUrl.searchParams;

	params.set(
		'plan',
		selectedPlan + (selectedDuration === 'monthly' ? '' : selectedDuration)
	);

	onboardingButton.href =
		onboardingUrl.origin + onboardingUrl.pathname + '?' + params.toString();

	onboardingButton.textContent = `${window.i18n.tiers.cta} ${window.i18n.tiers[selectedPlan]} ${window.i18n.tiers[selectedDuration]}`;
}

export default {
	init,
};
