function init() {
	const loginButtons = document.querySelectorAll(
		'[js-module~="loginRedirect"]'
	);

	loginButtons.forEach((loginButton) => {
		loginButton.addEventListener('click', setRedirectCookie, false);
	});
}

function setRedirectCookie(e) {
	const redirectUrl =
		e.currentTarget.dataset.redirectUrl || window.location.href;

	document.cookie = `lfvp_auth.redirect_uri=${redirectUrl};expires=0; path=/`;
}

export default {
	init,
};
