import profileTracking from '../profiles/profiles.tracking';
import kidslock from '../kidslock/kidslock';

function init() {
	// we have multiple user-dropdowns (one for mobile, one for desktop)
	const userDropdowns = document.querySelectorAll(
		'[js-module~="userDropdown"]'
	);
	if (!userDropdowns.length) return;

	userDropdowns.forEach((userDropdown) => {
		new UserDropdown(userDropdown);
	});
}

const UserDropdown = function (element) {
	let profilesAlreadyFetched = false;
	let firstFocusableElement = null;
	let lastFocusableElement = null;
	let dropdownOpen = false;

	const userDropdownButton = element.querySelector(
		'[js-element~="userDropdownButton"]'
	);
	const userDropdownWrapper = element.querySelector(
		'[js-element~="userDropdownWrapper"]'
	);
	const fallback = element.querySelector(
		'[js-element~="userDropdownFallback"]'
	);
	const profileContainer = element.querySelector(
		'[js-element~="userDropdownProfilesContainer"]'
	);
	const subscriptionContainer = element.querySelector(
		'[js-element~="userDropdownSubscriptionPlaceholder"]'
	);
	const backdrop = element.querySelector(
		'[js-element~="userDropdownBackdrop"]'
	);
	let originalActiveNavElements;

	userDropdownButton.addEventListener('click', clickHandler);

	function clickHandler() {
		// if it was open, close it
		if (dropdownOpen) {
			closeDropdown();
		} else {
			openDropdown();
		}
	}

	function closeDropdown() {
		element.classList.remove('is-active');
		originalActiveNavElements.forEach(function (el) {
			el.classList.add('is-active');
		});
		userDropdownButton.setAttribute('aria-expanded', 'false');
		dropdownOpen = false;
	}

	function openDropdown() {
		originalActiveNavElements = element
			.closest('.nav')
			.querySelectorAll('.is-active');
		originalActiveNavElements.forEach((el) => {
			el.classList.remove('is-active');
		});
		element.classList.add('is-active');
		// fetch the profiles
		// only for the first time
		if (!profilesAlreadyFetched && userDropdownWrapper.dataset.profileUrl) {
			getProfiles(userDropdownWrapper.dataset.profileUrl);
		} else {
			createFocusTrap();
			firstFocusableElement?.focus();
		}
		backdrop.addEventListener('click', globalClose);
		document.addEventListener('keydown', keydownHandler);
		userDropdownButton.setAttribute('aria-expanded', 'true');
		dropdownOpen = true;
	}

	async function getProfiles(profileUrl) {
		const response = await fetch(profileUrl, {
			method: 'GET',
			headers: {
				'Content-Type': 'text/xml',
				'X-Requested-With': 'XMLHttpRequest',
			},
		});

		if (!response.ok) {
			showError();
			return;
		}

		const content = await response.text();
		onSuccess(content);
		createFocusTrap();
		firstFocusableElement?.focus();
	}

	function onSuccess(response) {
		if (fallback.classList.contains('is-visible')) {
			fallback.classList.remove('is-visible');
		}

		const responseHTML = new DOMParser().parseFromString(response, 'text/html');

		const profilesResponse = responseHTML.querySelector(
			'[js-element~="profilesResponse"]'
		);

		const subscriptionResponse = responseHTML.querySelector(
			'[js-element~="subscriptionResponse"]'
		);

		profileContainer.classList.remove('is-hidden');
		profileContainer.innerHTML = profilesResponse.innerHTML;

		if (subscriptionContainer && subscriptionResponse) {
			subscriptionContainer.innerHTML = subscriptionResponse.innerHTML;
			subscriptionContainer.classList.remove('user-dropdown__item--dummy');
		}

		// only once ;-)
		profilesAlreadyFetched = true;
		// attach eventlisteners for tracking and kidslock
		profileTracking.init();
		kidslock.init();
	}

	function showError() {
		if (!fallback.classList.contains('is-visible')) {
			fallback.classList.add('is-visible');
		}

		if (!profileContainer.classList.contains('is-hidden')) {
			profileContainer.classList.add('is-hidden');
		}
	}

	function keydownHandler(event) {
		if (
			event.key === 'Tab' &&
			event.shiftKey &&
			document.activeElement === firstFocusableElement
		) {
			lastFocusableElement?.focus();
			event.preventDefault();
		} else if (
			event.key === 'Tab' &&
			document.activeElement === lastFocusableElement
		) {
			firstFocusableElement?.focus();
			event.preventDefault();
		}

		globalClose(event);
	}

	function globalClose(e) {
		if ((dropdownOpen && e.currentTarget === backdrop) || e.key === 'Escape') {
			closeDropdown();
			userDropdownButton.focus();

			backdrop.removeEventListener('click', globalClose);
			document.removeEventListener('keydown', keydownHandler);
		}
	}

	function createFocusTrap() {
		const allInteractiveElements =
			userDropdownWrapper.querySelectorAll('a, button');
		const focusableElements = [];

		allInteractiveElements.forEach((el) => {
			// checkVisibility not supported by Safari < 17.2,
			// fallback with clientrects = if it's visible it has a clientRect
			if (typeof el.checkVisibility === 'function') {
				if (el.checkVisibility()) {
					focusableElements.push(el);
				}
			} else {
				if (el.getClientRects().length) {
					focusableElements.push(el);
				}
			}
		});

		if (focusableElements.length) {
			firstFocusableElement = focusableElements[0];
			lastFocusableElement = focusableElements[focusableElements.length - 1];
		}
	}
};

export default {
	init,
};
